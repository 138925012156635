// @flow
import type { Node } from "react";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";

type Props = {
  value?: string,
  compare?: (*) => boolean,
  children: Node,
};

function ShowByValue({ compare = (v: *): boolean => Boolean(v), children, value = "" }: Props): Node {
  return compare(value) && children;
}

export default withFormField(ShowByValue);
