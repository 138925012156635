// @flow
/* eslint-disable react/require-default-props, react/default-props-match-prop-types */
import React, { type Node } from "react";

type Props = {
  id: string,
  colors?: string[],
  lineOffset?: number,
}

interface GradientDataType {
  stopColor: string,
  stopOpacity: number,
  offset: number,
}

/**
 * Component to define svg linear gradient, must be defined in svg -> defs > LinearGradient
 * @param {string} id of gradient must be unique in page
 * @param {string[]} colors of gradient uniform distribution of colors
 * @param {number} lineOffset percentage value start and end transparent offset of gradient must be lowered 1 / colors.length
 * @returns {JSX.Element} gradient component
 * @example define gradient
 * <svg><defs> <LinearGradient id="someGradientId" /> </defs></svg>
 * @example use for some svg
 * <svg> <circle r={5} fill="url(#someGradientId)" /> </svg>
 * @example use for mui icons
 * <Icon style={{ fill: "url(#someGradientId)"}}
 */
function LinearGradient({
  id, colors = ["#F00"], lineOffset = 0, ...props
}: Props): Node {
  const data: GradientDataType[] = colors
    .map((stopColor: string, index: number, array: string[]): GradientDataType => ({
      stopColor,
      offset: 1 / Math.max(1, array.length - 1) * index,
      stopOpacity: 1,
    }));

  if (lineOffset) {
    data[0].offset += lineOffset;
    data.unshift({
      stopColor: data[0].stopColor,
      offset: 0,
      stopOpacity: 0,
    });
    data[data.length - 1].offset -= lineOffset;
    data.push({
      stopColor: data[data.length - 1].stopColor,
      offset: 1,
      stopOpacity: 0,
    });
  }

  return (
    <linearGradient {...props} id={id} gradientUnits="objectBoundingBox">
      {data.map<Node>(({ stopColor, offset, stopOpacity }: GradientDataType): Node => (
        <stop
          key={stopColor + stopOpacity + offset}
          offset={offset}
          stopColor={stopColor}
          stopOpacity={stopOpacity}
        />
      ))}
    </linearGradient>
  );
}

export default LinearGradient;
